export const sleep = async (ms) => {
	return new Promise((resolve, reject) => {
		setTimeout(resolve, ms);
	});
};

export const tryParseBody = async (response) => {
	// Tries to parse the request body as JSON, avoiding errors when no JSON is
	// present in response (ie. when await response.json() throws)
	try {
		return await response.json();
	} catch (error) {
		console.log('tryParseBody - error:', { error });
		return undefined;
	}
};

export const retryFunc = async (func, delay, maxRetries) => {
	let retries = 0;
	while (retries <= maxRetries) {
		try {
			return await func();
		} catch (error) {
			console.log('retryFunc - error:', { error: error?.message, retries, maxRetries });
			await sleep(delay);
			retries++;
		}
	}
};
